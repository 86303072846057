.ba-info-item {
    display: flex;
    flex-direction: column;
    margin: $b-default-padding $b-large-padding 0 0;
    padding-right: $b-large-padding;

    &.ba-info-item--with-border {
        border-right: 1px solid $b-color-neutral-light-click;
    }

    .b-text {
        margin: 0;
    }

    .ba-info-item__value {
        display: flex;

        &.ba-info-item__value--uppercase {
            .b-text {
                text-transform: uppercase;
            }
        }
        &.ba-info-item__value--italic {
            .b-text {
                font-style: italic;
            }
        }

        .b-text {
            margin-top: $b-micro-padding;
            font-weight: $b-font-weight-medium;
        }

        .ba-info-item__copy {
            margin: -$b-small-padding 0 0 $b-small-padding;
        }

        white-space: pre-line;
    }
}
