body {
    font-family: 'Poppins', sans-serif;
    margin: 0;

    &::-webkit-scrollbar {
        width: $b-base;
        height: $b-base;
    }

    &::-webkit-scrollbar-thumb {
        background: $b-color-primary-default;
    }

    &::-webkit-scrollbar-track {
        background: inherit;
    }
}

.b-with-effects {
    transition: all 0.2s ease-in;
}

#b-body-content {
    flex: 1 0 auto;

    > .b-spinner {
        align-self: center;
    }
}
