.ba-damage {
    display: flex;
    flex-direction: column;

    .ba-damage__link {
        width: fit-content;
        margin-top: $b-default-padding;
        display: flex;
        align-self: flex-start;
    }
}
