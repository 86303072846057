.ba-messages {
    .ba-messages__title {
        padding: $b-default-padding 0 $b-micro-padding;
        font-size: $b-font-size-t5;
        line-height: $b-line-height-medium;
    }

    .ba-messages__scroll-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}
