.ba-page-link {
    display: inline-flex;
    border: 2px solid transparent !important;

    &:focus {
        outline: none;
        border-color: $b-color-info-focus !important;
        border-radius: $b-border-radius-minimal;
        box-shadow: none !important;
    }

    .b-text {
        margin: $b-micro-padding $b-small-padding;
    }

    .b-icon {
        margin: 0;
    }
}
