.ba-request-info {
    display: flex;
    flex-direction: column;

    .ba-request-info__wrapper {
        display: flex;
    }

    .ba-request-info__damage {
        display: flex;
        flex-direction: column;
        margin-bottom: $b-default-padding;

        .ba-request-info__damage__title {
            width: 100%;
            padding: $b-default-padding 0 $b-small-padding;
            font-size: $b-font-size-t5;
            line-height: $b-line-height-medium;
        }

        div {
            display: flex;

            .ba-request-info__damage__description {
                width: 70%;
                margin: 0;
                padding-bottom: $b-small-padding;

                .b-box__content {
                    position: relative;
                    padding: $b-default-padding $b-default-padding 0;

                    .ba-request-info__damage__description__header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .ba-request-info__damage__description-title {
                            font-weight: $b-font-weight-bold;
                            margin: 0;
                            width: fit-content;
                        }

                        .ba-copy-text {
                            position: absolute;
                            right: $b-base;
                        }
                    }
                }
            }
        }
    }

    .b-horizontal-rule {
        margin-top: $b-larger-padding * 2;
    }
}
