.b-images__title {
    font-weight: $b-font-weight-bold;
}
.b-images__container {
    display: flex;
    flex-wrap: wrap;
    .b-images__container-item {
        display: grid;
        place-items: center;
        padding-right: $b-default-padding;
        padding-bottom: $b-default-padding;
    }
}

.ba-request-info__damage__download {
    height: fit-content;
    padding: 0 $b-small-padding;
    border: 2px solid transparent;
    justify-content: left;
    width: 20%;

    .b-text {
        font-weight: $b-font-weight-medium;
        margin: $b-small-padding 0;
    }

    &:hover {
        .b-text {
            color: $b-color-info-hover;
        }
        .b-icon svg path {
            fill: $b-color-info-hover;
        }
    }

    &:focus {
        border-color: $b-color-info-focus;
        border-radius: $b-border-radius-minimal;
        box-shadow: none;
        .b-text {
            color: $b-color-info-focus;
        }
        .b-icon svg path {
            fill: $b-color-info-focus;
        }
    }

    &:active {
        .b-text {
            color: $b-color-info-click;
        }
        .b-icon svg path {
            fill: $b-color-info-click;
        }
    }
}
