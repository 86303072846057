.ba-list-item {
    border-bottom: 1px solid transparentize($b-color-black, 0.8);
    cursor: pointer;

    &.ba-list-item--with-background {
        background-color: $b-color-neutral-light-default;
    }

    &:hover {
        background-color: $b-color-info-light-hover;
    }

    &:active {
        background-color: $b-color-info-light-click !important;
    }

    &:focus {
        outline-color: $b-color-focus;
        background-color: $b-color-info-light-focus;
    }

    td {
        padding: $b-small-padding * 1.5 $b-default-padding;

        .b-text {
            font-weight: $b-font-weight-normal;
            display: inline-flex;
            margin: 0;
            font-size: $b-font-size-t1;

            &.ba-list-item__column--bold {
                font-weight: $b-font-weight-bold;
            }

            &.ba-list-item__column__hours {
                padding-left: $b-micro-padding;
            }
        }
    }
}
