.ba-list-header {
    border-bottom: 1px solid transparentize($b-color-info-default, 0.6);

    th {
        text-align: left;
        padding: $b-small-padding * 1.5 $b-default-padding;

        .b-text {
            margin: 0;
            font-size: $b-font-size-t1;
            font-weight: $b-font-weight-bold;
        }
    }
}
