.ba-copy-text {
    width: fit-content !important;
    border-radius: 50%;
    padding: calc(0.5em / 4);
    border: 2px solid transparent !important;
    @include background-color-transition;

    &:hover {
        background-color: $b-color-primary-light-hover;
    }
    &:focus {
        background-color: $b-color-primary-light-focus;
        box-shadow: none !important;
        border-color: $b-color-focus !important;
    }
    &:active {
        background-color: $b-color-primary-light-click;
    }

    .b-icon {
        svg * {
            &:hover {
                fill: $b-color-neutral-hover;
            }
            &:focus {
                fill: $b-color-neutral-focus;
            }
            &:active {
                fill: $b-color-neutral-click;
            }
        }
    }
}
