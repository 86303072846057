$ba-z-index-header-search: 100;

.ba-search {
    display: flex;
    position: relative;
    z-index: $ba-z-index-header-search;
    margin-bottom: $b-base * 14;

    .ba-search__page {
        position: absolute;
        padding: 0;
        z-index: $ba-z-index-header-search;
        transition: box-shadow ease-in-out 200ms;

        &.ba-search__page--active {
            background-color: $b-color-white;
            box-shadow: $b-border-bottom-box-shadow;

            .b-page__content {
                min-height: $b-base * 20;

                .ba-search__page__clear {
                    display: flex;
                }
            }
        }

        .b-page__content {
            position: static;
            display: flex;
            align-items: center;
            padding: $b-default-padding 0 !important;
            z-index: $ba-z-index-header-search;

            .b-search-input {
                margin: $b-default-padding 0;
                display: flex;
                align-self: center;
            }

            .ba-search__page__clear {
                display: none;
                width: fit-content;
                position: absolute;
                right: $b-default-padding;
                top: $b-default-padding;
                z-index: $ba-z-index-header-search;
            }

            .ba-search__page__text {
                margin: $b-small-padding 0;
            }

            .ba-search__page__loader {
                display: flex;
                align-items: center;
                justify-content: center;

                .b-spinner {
                    width: $b-base * 3;
                    height: $b-base * 3;
                }
            }

            .ba-search__page__results {
                width: 100%;
                margin-bottom: $b-larger-padding;
            }
        }
    }
}

.ba-search__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background: transparentize($b-color-white, 0.2);
    animation: b-loading-overlay-fade-in ease 0.5s 1;
    animation-fill-mode: forwards;
}

@keyframes b-loading-overlay-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
