.ba-statistics__details__container {
    width: 100%;
    background-color: $b-color-white;
    padding: $b-default-padding 0;

    .ba-statistics__details__content--topElementShade {
        padding: $b-small-padding;
        &:first-child {
            border-radius: $b-default-border-radius;
            background-color: $b-color-neutral-light-default;
            .b-text {
                font-weight: $b-font-weight-bold;
                font-size: $b-font-size-t3;
            }
        }
    }

    .ba-statistics__details__content {
        display: flex;
        justify-content: space-between;
        padding: 0 $b-default-padding;
        &:not(:first-child) {
            border-bottom: 1px solid $b-color-neutral-light-click;
        }

        .ba-statistics__details__content-value {
            font-weight: $b-font-weight-bolder;
        }
    }
}
