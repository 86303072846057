.ba-damage-statistics__content {
    .ba-damage-statistics__content-spinner {
        display: flex;
        justify-content: center;
    }

    .ba-damage-statistics__content-title {
        font-weight: $b-font-weight-bold;
        font-size: $b-font-size-t5;
    }
    .ba-damage-statistics__content-cards {
        padding-top: $b-large-padding;
        display: flex;
        justify-content: center;
        padding-bottom: $b-default-padding;

        > *:not(:last-child) {
            margin-right: $b-large-padding;
        }
    }
    .ba-damage-statistics__content-statistics {
        display: flex;
        > *:not(:last-child) {
            margin-right: $b-large-padding;
        }
    }
}
