.ba-damage-list {
    .ba-damage-list__header {
        font-size: $b-font-size-t4;
        padding: $b-small-padding 0;
    }

    .ba-damage-list__filter {
        display: flex;
        gap: 16px;
    }

    .ba-damage-list__select {
        background: white;
        border: 1px solid grey;
        padding: 4px 6px;
        border-radius: 10px;
    }

    .ba-damage-list__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
    }

    .ba-damage-list__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
    }
}
