.ba-upload-button {
    display: flex;
    width: fit-content;
    height: fit-content;
    position: relative;

    .ba-upload-button__label {
        display: flex;
        position: relative;
        transition: all 0.3s linear;
        height: $b-base * 10;
        width: $b-base * 10;
        padding: $b-small-padding;
        background-color: $b-color-info-light-default;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;

        &:hover {
            background-color: $b-color-info-light-hover;
        }
        &:focus {
            background-color: $b-color-info-light-focus;
        }
        &:active {
            background-color: $b-color-info-light-click;
        }

        .ba-upload-button__input {
            width: 0;
            height: 0;
            border-radius: 0;
            border: none;
            outline: 0;
            background: transparent;
        }

        .b-icon {
            padding: $b-small-padding 0 0;
            font-weight: $b-font-weight-bold;
        }

        .b-text {
            text-align: center;
            font-size: $b-font-size-t1;
            margin: $b-micro-padding $b-small-padding;
            width: 100%;
        }
    }
}

.ba-invalid-upload {
    max-width: $b-base * 60;
    min-width: unset;
    margin: 0;

    .b-modal__content-wrapper {
        flex-direction: column;

        .b-button {
            width: fit-content;
            align-self: flex-end;
        }
    }
}
