.ba-request-shortlist {
    display: flex;
    flex-direction: column;

    .ba-request-shortlist__see-all {
        margin: $b-small-padding 0 0 auto;
        display: flex;
        align-self: flex-end;
    }

    .ba-request-shortlist__error {
        margin-top: $b-larger-padding !important;
    }
}
