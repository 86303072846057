.message-table-container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.button-show-older-cases {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 1rem;

    border: 1px solid #333;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
    color: #3744b9;
    padding: 0.5rem 1rem;

    margin-top: 1rem;
    float: right;

    &:hover {
        opacity: 0.7;
    }
}

.ended-conversations-container {
    margin-top: 2rem;
}
