$ba-menu-width: $b-base * 20;
$ba-menu-width-total: $ba-menu-width + $b-default-padding * 2;

.ba-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: $ba-menu-width;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $b-color-neutral-click;
    color: $b-color-white;
    padding: 0 $b-default-padding $b-default-padding $b-default-padding;
    z-index: $b-z-index-menu;

    .ba-menu__header-icon {
        padding-bottom: $b-large-padding;

        svg {
            polygon {
                fill: $b-color-white;
            }
            &:hover {
                polygon {
                    fill: $b-color-primary-hover;
                }
            }
        }
    }

    .ba-menu__item-active {
        &.b-icon-text {
            .ba-menu__menu-item__content__text {
                color: $b-color-primary-hover;
                font-weight: $b-font-weight-medium !important;
            }
        }
    }
}

.ba-menu__hidden {
    display: none !important;
}

.ba-menu__lower-content {
    position: fixed;
    bottom: $b-default-padding;
    left: $b-default-padding;
}

.ba-menu__menu-item__content {
    font-size: $b-font-size-t2;

    &.b-icon-text {
        .ba-menu__menu-item__content__text {
            color: $b-color-white;
        }
        &:hover {
            box-shadow: none;

            .ba-menu__menu-item__content__text {
                transform: scale(1.05);
                color: $b-color-primary-hover;
            }
        }
        &:focus {
            background: none;
        }
    }

    .ba-menu__menu-item__content__text {
        font-weight: $b-font-weight-thin !important;
    }
}

.ba-menu__toggle {
    position: fixed;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    transition: 0.5s ease;
    text-align: center;
    opacity: 1;
    z-index: $b-z-index-menu - 1;
}
