.ba-damage-statistics__filter {
    display: flex;
    flex-direction: row;
    padding-bottom: $b-default-padding;

    > * {
        padding-right: $b-default-padding;
    }

    .ba-damage-statistics__select-damage-center {
        width: 100%;
    }
}
