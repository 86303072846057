.ba-statistics-card {
    width: 100%;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.4));
    border-radius: $b-default-border-radius;

    .ba-statistics-card__icon-text__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: $b-default-padding $b-large-padding 0;

        .b-icon-text__text {
            font-weight: $b-font-weight-normal;
        }
        .ba-statistics-card__icon-text__right {
            font-weight: $b-font-weight-bold;
        }
    }
    .ba-statistics-card__content-text {
        display: flex;
        padding-left: $b-larger-padding * 3;
        padding-bottom: $b-default-padding * 2;
        .b-text {
            margin: 0;
            font-weight: $b-font-weight-bold;
            font-size: $b-font-size-t5;
        }
    }
}
