.ba-request {
    .b-page__content {
        .ba-list-page__back {
            display: flex;
            align-self: flex-start;
            width: auto;
            margin: $b-default-padding 0;
        }

        .ba-request__error {
            margin-top: $b-larger-padding;
        }
    }

    .ba-request__close-conversation-container {
        display: flex;
        justify-content: flex-end;
    }

    .ba-request__ended-conversation-paragraph {
        display: flex;
        justify-content: flex-end;
    }
}
