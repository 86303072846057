.ba-damage-statistics {
    .b-spinner {
        align-self: center;
    }
    .ba-damage-statistics__back {
        display: flex;
        align-self: flex-start;
        margin: $b-default-padding 0;
    }

    .ba-damage-statistics__title {
        margin-top: 0;
        font-weight: $b-font-weight-bold;
        font-size: $b-font-size-t6;
    }
}
