.ba-list-page {
    .b-page__content {
        .ba-list-page__back {
            display: flex;
            align-self: flex-start;
            margin: $b-default-padding 0;
        }

        .ba-list-page__spinner {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: $b-large-padding;
            .b-spinner {
                width: $b-base * 3;
                height: $b-base * 3;
            }
        }

        .ba-list-page__error {
            margin-top: $b-larger-padding !important;
        }

        .ba-list-page__button {
            width: fit-content;
            margin: $b-large-padding auto $b-default-padding;
        }
    }
}
