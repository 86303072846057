.ba-upload-valuation {
    display: flex;

    .ba-upload-valuation__column {
        margin-top: $b-larger-padding;
        width: 33.33%;
        padding-right: $b-default-padding;

        .b-input-component {
            margin-top: calc(#{$b-default-padding} + 1px);
        }
    }

    .ba-upload-valuation__attachments {
        display: flex;
        width: 66.66%;
        flex-wrap: wrap;

        &.ba-upload-valuation__attachments--single {
            width: 33.33%;

            .ba-upload-valuation__attachments__wrapper {
                width: 100%;
            }
        }

        .ba-upload-valuation__attachments__wrapper {
            display: flex;
            flex-direction: column;
            width: 50%;
            margin-top: $b-larger-padding;

            .ba-upload-valuation__attachments__wrapper__item {
                display: flex;
                height: $b-base * 10;
                width: $b-base * 10;
                padding: $b-small-padding;
                background-color: $b-color-neutral-light-click;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .b-text {
                    text-align: center;
                    font-size: $b-font-size-t1;
                    margin: $b-micro-padding $b-small-padding;
                    width: 100%;
                    word-break: break-all;
                }
            }

            .ba-upload-valuation__attachments__wrapper__input {
                padding-right: $b-default-padding;

                .b-input-component {
                    margin-top: $b-default-padding;
                }
            }
        }
    }
}
