.ba-request-info-header {
    display: flex;
    width: 100%;
    align-items: center;

    .ba-request-info-header__button {
        display: flex;
        gap: 20px;
        margin-left: auto;
    }
}
