// Overrides for variables that are set in @carcare/components and used in this application
$b-header-height: $b-base * 8;

// These variables are used in Page component, and are thus compiled to actual values in the
// component stylesheet. Therefore, merely overriding the variables is not enough.
// We must also override the style which uses them, with higher specificity.
$b-default-page-max-width: $b-base * 140;
$b-max-page-width: 95%;
@include b-breakpoint($b-breakpoint-tablet) {
    .bilhold.b-page .b-page__content {
        width: $b-max-page-width;
        max-width: $b-default-page-max-width;
    }
}
