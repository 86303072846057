.ba-new-message-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .ba-new-message-content__inputs {
        display: flex;
        flex-direction: column;
        width: calc(33.33% - #{$b-default-padding});
        margin-top: $b-larger-padding;

        .b-input-component {
            margin-top: $b-larger-padding;

            &:first-of-type {
                margin-top: 0;
            }
        }
    }

    .ba-new-message-content__bottom {
        display: flex;
        align-items: flex-end;

        .ba-new-message-content__bottom__text-area {
            display: flex;
            width: 70%;
            margin: $b-larger-padding 0 0;

            .b-text-area__text-area {
                min-height: $b-base * 30;
            }
        }

        .ba-new-message-content__bottom__buttons {
            display: flex;
            justify-items: flex-end;
            align-self: flex-end;
            height: fit-content;
            margin-left: $b-default-padding;

            .b-button:first-of-type {
                margin-right: $b-default-padding;
            }
        }
    }
}
