.ba-complete-modal {
    display: flex;
    padding: $b-larger-padding;
    margin-top: 0;

    .b-modal__content-wrapper {
        display: flex;
        flex-direction: column;

        .ba-complete-modal__title {
            font-size: $b-font-size-t5;
            font-weight: $b-font-weight-bold;
            margin-top: 0;
        }

        .b-radio-button {
            margin-bottom: $b-larger-padding;
        }

        .ba-complete-modal__error {
            margin: 0 0 $b-default-padding;
        }

        .ba-complete-modal__buttons {
            display: flex;
            justify-content: flex-end;

            .b-button:first-of-type {
                margin-right: $b-default-padding;
            }
        }
    }
}
