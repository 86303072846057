.ba-new-message {
    .ba-new-message__title {
        padding: $b-default-padding 0 $b-micro-padding;
        font-size: $b-font-size-t5;
        line-height: $b-line-height-medium;
    }

    .ba-new-message__wrapper {
        display: flex;
        flex-direction: column;

        .ba-new-message__wrapper__templates {
            display: inline-flex;
            flex-wrap: wrap;
            width: 70%;

            .b-radio-button {
                display: flex;
                flex: 1 1 calc(50% - #{$b-larger-padding});
                margin-top: $b-larger-padding;

                &:nth-of-type(odd) {
                    margin: $b-larger-padding $b-larger-padding 0 0;
                }
            }
        }
    }
}
