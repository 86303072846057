#ba-header {
    position: relative;
    transition: opacity 0.2s;
    opacity: 1;

    &.ba-header-padding {
        padding-bottom: $b-header-height;
    }

    &.ba-header--ie {
        padding-bottom: $b-header-height + $b-header-ie-height;
    }
}

.ba-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $b-z-index-header-core;
    display: flex;
    flex-wrap: wrap;

    .ba-header__content {
        position: relative;
        padding: $b-base $b-base * 2;
        box-sizing: border-box;
        background: $b-color-neutral-click;
        box-shadow: $b-border-default-box-shadow;
        width: 100%;
        height: $b-header-height;
        align-items: center;
        justify-content: space-between;
        display: flex;
        pointer-events: auto;

        .ba-header__content-first-group {
            display: flex;
            align-items: center;

            .ba-header__text {
                display: flex;
                align-items: center;
                text-decoration: none;

                .b-text {
                    font-weight: $b-font-weight-bold;
                    padding: 0 $b-small-padding;
                    color: $b-color-primary-default;
                    margin: 0;
                }

                > * {
                    border-radius: 0;
                    height: $b-larger-padding;
                    min-height: $b-larger-padding;
                }
            }
        }

        .ba-header__content-second-group {
            display: flex;

            .ba-header__content-second-group__translations {
                display: flex;
                align-self: center;
                margin-left: $b-default-padding;

                > * {
                    font-size: $b-font-size-t0;
                }

                .b-clickable {
                    img {
                        height: $b-base * 4;
                        width: $b-base * 4;
                        padding: $b-small-padding;
                    }
                }
            }
        }

        .ba-header__text-name {
            color: $b-color-white;
        }

        .ba-header-menu-icon {
            cursor: pointer;
            &:focus {
                outline: none;
            }
        }
    }
}
