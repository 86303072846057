.ba-ie-header {
    z-index: $b-z-index-header-core;
    position: fixed;
    top: $b-header-height;
    height: $b-header-ie-height;
    width: 100%;
    padding: 0 $b-default-padding;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $b-default-padding;

    .b-text {
        margin-left: $b-small-padding;
        font-weight: $b-font-weight-bolder;
        font-size: $b-font-size-t1;
    }
}
